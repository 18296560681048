import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { UserApi } from '../../api/UserApi'

const initialState = {
  data:[],
  authState: 'initial',
  details:{}
}

//To get logged in user details
export const getLoggedInUserDetails = createAsyncThunk( 'userDetails/getLoggedInUserDetails', async ()=>{
  return UserApi.getLoggedInUserDetails( action );
} )

//Administration User management tab thunks
//To get paginated Users data
export const getUsers = createAsyncThunk( 'administration/getUsers', async ( action:{ page:number, limit:number, searchCode?: string } )=>{
  return UserApi.getUsers( action );
} )

//To create an user
export const createUser = createAsyncThunk( 'userDetails/createUser', async ( action:{ request } )=>{
  return UserApi.createUser( action );
} )

//To update an user
export const updateUser = createAsyncThunk( 'userDetails/updateUser', async ( action:{ request } )=>{
  return UserApi.updateUser( action );
} )

//To delete user
export const deleteUsers = createAsyncThunk( 'userDetails/deleteUsers', async ( action:{ request } )=>{
  return UserApi.deleteUsers( action );
} )

//To update user role associations
export const updateUserRoleAssociation = createAsyncThunk( 'userDetails/updateUserRoleAssociation', async ( action:{ request } )=>{
  return UserApi.updateUserRoleAssociation( action );
} )

//To get a user details
export const getUserDetails = createAsyncThunk( 'userDetails/getUserDetails', async ( action:{ request } )=>{
  return UserApi.getUserDetails( action );
} )


const userSlice = createSlice( {
  name:'userDetails',
  initialState,
  reducers:{
    setAuthState( state, action ) {
      state.authState = action.payload
    },
  },
  extraReducers: ( builder ) =>{
    builder.addCase( getLoggedInUserDetails.fulfilled, ( state, action ) => {
      if( !action.payload.error ) {
        state.details = action.payload.data
      }
    } )
  }
} )

export const { setAuthState } = userSlice.actions

export const userSettings = ( state ) => state

export default userSlice.reducer