import { Alert, AlertTitle, Box,Button,Checkbox,Collapse,FormControlLabel,Grid,Paper,Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore, AppDispatch, AppState } from '../../../store';
import { useTranslation } from 'react-i18next';
import { foreignKeysForTable,EActionType,TableHeaders,EAction, RegExPatterns } from '../../../data/Constants';
import { IError, IManipulationProps } from '../../../../types';
import { ErrorMessage } from '../../ErrorMessage';
import { getUsers, createUser, getUserDetails, updateUser, updateUserRoleAssociation } from '../../../store/states/UserSlice';
import { resetDialogMessage } from '../../../store/states/DialogSlice';
import { getAllRoles } from '../../../store/states/RoleSlice'
import { resetError } from '../../../store/states/ErrorSlice';
import { setAlertMessage, resetAlertMessage } from '../../../store/states/AlertMessageSlice';
import { resetAllErrorInfo } from '../../../services/DataHelperFunctions';

const uniqueKeyForRole:string = foreignKeysForTable['roles'];

const loadFormData = async ( loadFormDataprops ) => {
  const {props, id, formData, setFormData, initialUserData, setInitialUserData, setSelected, dispatch} = loadFormDataprops;
  let userDetails = await dispatch( getUserDetails( {token: props.token, request: [id]} ) ).unwrap()
  
  if( !userDetails.data ) {
    return;
  }
  userDetails = userDetails.data[0];
  setFormData( {...formData,'name':userDetails.name,'emailId':id,'isAdmin':userDetails.isAdmin,'isActive':userDetails.isActive,'isService':userDetails.isService,'isPdm':userDetails.isPdm,'isAce':userDetails.isAce} )
  const assignedRoles = userDetails.roles.map( role =>{
    return role[uniqueKeyForRole]
  } ) 
  setInitialUserData( {...initialUserData,
    'personalDetails':{'name':userDetails.name,'emailId':id,'isAdmin':userDetails.isAdmin,'isActive':userDetails.isActive,'isService':userDetails.isService,'isPdm':userDetails.isPdm,'isAce':userDetails.isAce},
    'roles':assignedRoles} )
  setSelected( assignedRoles );

}

const loadInitialData = ( functionProps ) => {
  const {props, id, formData, setFormData, initialUserData, setInitialUserData, setSelected, dispatch} = functionProps;
  if( functionProps.manipulationType === EActionType.Edit ) {
    setFormData( {...functionProps.formData,'emailId':functionProps.id} )
    loadFormData( {props,id,formData,setFormData,initialUserData,setInitialUserData,setSelected, dispatch} )
  }
  dispatch( getAllRoles( {token: props.token,page: 0,limit: 0, searchCode: null} ) );

}

const addDefaultRole = ( defaultRole, manipulationType, selected, setSelected ) => {
  if( defaultRole && manipulationType === EActionType.Create ) {
    setSelected( [...selected,defaultRole] )
  }
}

const setLocalAlertMessage = ( error: IError, setDisplayAlertMessage ) => {
  if( error.code && ( error.action === EAction.Create || error.action === EAction.Update ) ) {
    setDisplayAlertMessage( true );
  }    
}

const formValidation = ( formData, selected, initialUserData,stringValidations, setDisplayNameErrorMessage, setDisplayEmailErrorMessage, setDisableButton ) => {
  const name = formData.name.trimEnd();
  const validName = name.length >= stringValidations.name.min && RegExPatterns.UserName.test( name ) && name.split( '(' ).length <= stringValidations.word.max;
  const validEmail = RegExPatterns.Email.test( formData.emailId.toLowerCase() );
  setDisplayNameErrorMessage( !validName );
  setDisplayEmailErrorMessage( !validEmail );
  const personalDetailsUpdated = JSON.stringify( {...formData,name} ) !== JSON.stringify( initialUserData.personalDetails )
  const rolesUpdated = JSON.stringify( selected.sort() ) !== JSON.stringify( initialUserData.roles.sort() )
  setDisableButton( !validName || !validEmail || !personalDetailsUpdated && !rolesUpdated )
}

const handleChange = ( event, formData, setFormData ) => {
  const name = event.target.name;
  let value = '';
  switch( name ) {
    case 'isAdmin':
      value = event.target.checked;
      break;
    case 'isActive':
      value = event.target.checked;
      break;
    case 'isService':
      value = event.target.checked;
      break;
    case 'emailId':
      value = event.target.value.toLowerCase();
      break;
    case 'isPdm':
      value = event.target.checked;
      break;
    case 'isAce':
      value = event.target.checked;
      break;
    default:
      value = event.target.value;
  }
  setFormData( {...formData, [name] :value} )
}

const handleSelectAllClick = ( event: React.ChangeEvent<HTMLInputElement>, props, defaultRole, manipulationType, initialUserData, roles, setSelected ) => {
  if( event.target.checked ) {
    const newSelected = roles.map( ( n ) => n[uniqueKeyForRole] );
    setSelected( newSelected );
  } else if( initialUserData.roles?.includes( defaultRole ) || manipulationType === EActionType.Create ) {
    setSelected( [defaultRole] )
  } else{
    setSelected( [] );
  }
}

const handleClick = ( event, value, selected, setSelected ) => {
  const selectedIndex = selected.indexOf( value );
  let newSelected: readonly string[] = [];
  if ( selectedIndex === -1 ) {
    newSelected = newSelected.concat( selected, value );
  } else if ( selectedIndex === 0 ) {
    newSelected = newSelected.concat( selected.slice( 1 ) );
  } else if ( selectedIndex === selected.length - 1 ) {
    newSelected = newSelected.concat( selected.slice( 0, -1 ) );
  } else if ( selectedIndex > 0 ) {
    newSelected = newSelected.concat(
      selected.slice( 0, selectedIndex ),
      selected.slice( selectedIndex + 1 ),
    );
  }
  setSelected( newSelected );
};

const handleAPIResponse = ( res, props, dispatch:AppDispatch, errorMessage:string, successMessage:string ) => {
  const users = AppStore.getState().administration.users;
  
  if( res?.data ) {
    dispatch( getUsers( {token: props.token,page: users.currentPage,limit: users.recordsPerPage,searchCode: users.searchKey} ) )
    dispatch( setAlertMessage( {show:true,message:successMessage,type:'success'} ) )
    dispatch( resetDialogMessage() )
  }else{
    const message = res?.error?.message ? res.error.message : errorMessage
    dispatch( setAlertMessage( {show:true,message:message,type:'error'} ) )
  }
}

const handleCreate = ( props, formData, selected, dispatch: AppDispatch, t ) => {
  dispatch( createUser( {token: props.token,request: {'userInfo':{...formData,name:formData.name.trimEnd()},'roles':selected}} ) ).then( res=>{
    handleAPIResponse( res.payload, props, dispatch, t( 'messages.fail.create' ), t( 'messages.success.create' ) )
  } )
}

const handleUpdateUserDetails = ( props, formData, dispatch: AppDispatch, t ) => {
  dispatch( updateUser( {token: props.token, request: {...formData,name:formData.name.trimEnd()}} ) ).then( res=>{
    handleAPIResponse( res.payload, props, dispatch, t( 'messages.fail.update' ), t( 'messages.success.update' ) )
  } )
}

const handleUpdateUserRoles = ( props, formData, selected, dispatch: AppDispatch, t ) => {
  dispatch( updateUserRoleAssociation( {token: props.token,request: {'emailId':formData.emailId,'roles':selected}} ) ).then( res=>{
    handleAPIResponse( res.payload, props,dispatch, t( 'messages.fail.update' ), t( 'messages.success.update' ) )
  } )
}

const handleUpdateCompleteUser = ( props, formData, selected, dispatch: AppDispatch, t ) => {
  dispatch( updateUser( {token: props.token, request: {...formData,name:formData.name.trimEnd()}} ) ).then( res=>{
    if( !res || res.payload?.error ) {
      const message = res?.payload.error.message ? res.payload.error.message : t( 'messages.fail.update' )
      dispatch( setAlertMessage( {show:true,message:message,type:'error'} ) )
    }else{
      handleUpdateUserRoles( props,formData, selected, dispatch, t )
    }
  } )
}

const handleSubmit = ( handleSubmitProps ) => {    
  const { event, props, formData, selected, initialUserData, manipulationType, t,setDisplayAlertMessage, dispatch } = handleSubmitProps;
  event.preventDefault();
  const personalDetailsUpdated = JSON.stringify( {...formData,name:formData.name.trimEnd()} ) !== JSON.stringify( initialUserData.personalDetails )
  const rolesUpdated = JSON.stringify( selected.sort() ) !== JSON.stringify( initialUserData.roles.sort() )
  dispatch( resetError() );
  setDisplayAlertMessage( false );
  switch( manipulationType ) {
    case EActionType.Create:
      handleCreate( props, formData, selected, dispatch, t )
      break;
    case EActionType.Edit:
      if( personalDetailsUpdated && rolesUpdated ) {
        handleUpdateCompleteUser( props, formData, selected, dispatch, t )
      } else if( personalDetailsUpdated ) {
        handleUpdateUserDetails( props, formData , dispatch, t )
      } else if( rolesUpdated ) {
        handleUpdateUserRoles( props,formData, selected, dispatch, t )
      }
      break;        
  }
}

const disableRole = ( row, manipulationType:string, initialUserData ) => {  
  if( manipulationType === EActionType.Edit ) {
    if( !initialUserData.personalDetails.isActive ) {
      return true
    } else if( initialUserData.roles && row.isDefault ) {
      return initialUserData.roles.includes( row[uniqueKeyForRole] )
    }
  } else if( manipulationType === EActionType.Create && row.isDefault ) {
    return true;
  }
  return false;  
}

function getHeader( manipulationType:string,t:( arg: string, arg2?: object ) => string ) {
  return manipulationType === EActionType.Create ? t( 'labels.createUser' ) : t( 'labels.editUser' )  
}

const handleClose = ( event, props, setDisplayAlertMessage, dispatch, reason? ) => {
  if ( reason === 'clickaway' ) {
    return;
  }
  dispatch( resetAlertMessage() );
  dispatch( resetError() );
  setDisplayAlertMessage( false );
}

const createCell = ( row,c )=>{
  if ( c.type === 'checkbox' ) {
    return <Checkbox value={ row[c.field] } checked={ row[c.field] } disabled/> 
  } else {
    return row[c.field];
  }
}

//This method returns the true or false based on isActive flag
const isDisabled = ( manipulationType:string, isActive:boolean )=>{
  return manipulationType === EActionType.Edit && !isActive
}

export const UserManipulation = ( props:IManipulationProps ) => {

  const {t} = useTranslation();
  const [tabValue,setTabValue] = useState( 'PersonalDetails' );
  const [formData,setFormData] = useState( {'name':'','emailId':'','isAdmin':false,'isActive':true,'isService':false,'isPdm':false,'isAce':false} );
  const [disableButton,setDisableButton] = useState( true );
  const [displayNameErrorMessage,setDisplayNameErrorMessage] = useState( false );
  const [displayEmailErrorMessage,setDisplayEmailErrorMessage] = useState( false );
  const [displayAlertMessage,setDisplayAlertMessage] = useState( false );  
  const [selected, setSelected] = React.useState<string[]>( [] );
  const [defaultRole,setDefaultRole] = React.useState<string>( );
  const [initialUserData,setInitialUserData] = useState( {'personalDetails':{ 'name':'','emailId':'','isAdmin':false,'isActive':true,'isService':false },'roles':[] } )
  const stringValidations = {
    name: { min: 3},
    word: {max: 2}
  };
  
  const manipulationType = props.type; //To get the type of manipulation being performed
  const id = manipulationType === EActionType.Edit && props.id;
  const header = getHeader( manipulationType,t );

  const isSelected = ( value: string ) => selected.indexOf( value ) !== -1;  
  const headers = TableHeaders['roleAssociationTab'].main;

  const dispatch = useDispatch();
  const error = useSelector( ( state:AppState )=> state.error );
  const roles = AppStore.getState().roles.data?.filter( role=> role.isActive );

  const rowCount = roles.length;
  

  useEffect( ()=>{
    loadInitialData( {props, id, header, manipulationType, formData, setFormData, initialUserData, setInitialUserData, loadFormData,dispatch, setSelected } )
  },[] )

  useEffect( ()=>{
    const tempDefaultRole:string = roles?.find( r=>r.isDefault )?.[uniqueKeyForRole];
    setDefaultRole( tempDefaultRole )
  },[roles] )

  useEffect( ()=>{
    addDefaultRole( defaultRole, manipulationType, selected, setSelected )
  },[defaultRole] )

  useEffect( () => { 
    setLocalAlertMessage( error, setDisplayAlertMessage );
  }, [ error ] ) 

  useEffect( ()=>{
    formValidation( formData, selected, initialUserData,stringValidations, setDisplayNameErrorMessage, setDisplayEmailErrorMessage, setDisableButton )
  } )

  const handleTabChange = ( ...params:[React.BaseSyntheticEvent, string] ) => {
    setTabValue( params[1] );
  }; 

  return <Box className="userManipulationTabs">
    <TabContext value={ tabValue || 'PersonalDetails' }>
      <Box className="dialog-box">
        <TabList onChange={ handleTabChange } >
          <Tab className="dialog-tab text-capitalize"
            value="PersonalDetails" label={ t( 'labels.personalDetails' ) }
          />
          <Tab className="dialog-tab text-capitalize" 
            value="RoleAssociation" label={ t( 'labels.roleAssociation' ) }
          />
        </TabList>
      </Box>

      <TabPanel value="PersonalDetails" sx={ {} } className="personalDetailsTab" >
        <Box className="personalDetailsForm">
          <Collapse in={ displayAlertMessage } className="show-alert" >
            <Alert className="errorMessage" severity="error" onClose={ ( event ) => {
              handleClose( event, props, setDisplayAlertMessage, dispatch )
            } }
            >
              <AlertTitle><ErrorMessage error={ error } manipulationType={ manipulationType }/> </AlertTitle>
            </Alert>
          </Collapse>
          <TextField name="name" label={ t( 'labels.name' ) } required variant="outlined" size="small" fullWidth value={ formData.name } onChange={ ( e )=>{
            handleChange( e, formData, setFormData )
          } } disabled={ manipulationType === EActionType.Edit && !formData.isActive }
          /><br/>
          <Collapse in={ displayNameErrorMessage && formData.name.length > 0 }>
            <Alert className="errorMessage" severity="error">
              <AlertTitle>{t( 'messages.nameErrorMessage' )} </AlertTitle>
            </Alert>
          </Collapse><br/>

          <TextField name="emailId" label={ t( 'labels.email' ) } required variant="outlined" size="small" fullWidth value={ formData.emailId } onChange={ ( e )=>{
            handleChange( e, formData, setFormData )
          } } disabled={ manipulationType === EActionType.Edit }
          /><br/>
          <Collapse in={ displayEmailErrorMessage && formData.emailId.length > 0 }>
            <Alert className="errorMessage" severity="error">
              <AlertTitle>{t( 'messages.emailErrorMessage' )}</AlertTitle>
            </Alert>
          </Collapse>

          <fieldset className="inputFieldset w-100 mt-1em">
            <legend className="inputLegend">{t( 'labels.userAccessType' )} </legend>
            <Grid container spacing={ 2 } className="pt-1">
              <Grid item xs={ 4 } key="isAdmin" className="pt-0">
                <FormControlLabel
                  key="isAdmin"
                  value={ formData.isAdmin }
                  checked={ formData.isAdmin }
                  disabled = { isDisabled( manipulationType ,formData.isActive ) }
                  control={ <Checkbox /> }
                  label={ t( 'labels.isAdmin' ) }
                  labelPlacement="end"
                  onChange={ ( e )=>{
                    handleChange( e, formData, setFormData ) 
                  } }
                  name="isAdmin"
                />
              </Grid>

              <Grid item xs={ 4 } key="isService" className="pt-0">
                <FormControlLabel
                  key="isService"
                  value={ formData.isService }
                  checked={ formData.isService }
                  disabled = { isDisabled( manipulationType ,formData.isActive ) }
                  control={ <Checkbox /> }
                  label={ t( 'labels.isService' ) }
                  labelPlacement="end"
                  onChange={ ( e )=>{
                    handleChange( e, formData, setFormData ) 
                  } }
                  name="isService"
                />      
              </Grid>      

              <Grid item xs={ 4 } key="isActive" className="pt-0">
                {!initialUserData.personalDetails.isActive && manipulationType === EActionType.Edit ?
                  <FormControlLabel
                    key="isActive"
                    value={ formData.isActive }
                    checked={ formData.isActive }
                    control={ <Checkbox /> }
                    label={ t( 'labels.isActive' ) }
                    labelPlacement="end"
                    onChange={ ( e )=>{
                      handleChange( e, formData, setFormData )
                    } }
                    name="isActive"
                  /> : null
                }
              </Grid>

              <Grid item xs={ 4 } key="isAce" className="pt-0">
                <FormControlLabel
                  key="isAce"
                  value={ formData.isAce }
                  checked={ formData.isAce }
                  disabled = { isDisabled( manipulationType ,formData.isActive ) }
                  control={ <Checkbox /> }
                  label={ t( 'labels.isAce' ) }
                  labelPlacement="end"
                  onChange={ ( e )=>{
                    handleChange( e, formData, setFormData ) 
                  } }
                  name="isAce"
                /> 
              </Grid>

              <Grid item xs={ 4 } key="isPdm" className="pt-0">
                <FormControlLabel
                  key="isPdm"
                  value={ formData.isPdm }
                  checked={ formData.isPdm }
                  disabled = { isDisabled( manipulationType ,formData.isActive ) }
                  control={ <Checkbox /> }
                  label={ t( 'labels.isPdm' ) }
                  labelPlacement="end"
                  onChange={ ( e )=>{
                    handleChange( e, formData, setFormData ) 
                  } }
                  name="isPdm"
                /> 
              </Grid>
            </Grid>
          </fieldset>
        </Box>
      </TabPanel>

      <TabPanel value="RoleAssociation" className="roleAssociationTab">
        <Collapse in={ displayAlertMessage } className="show-alert">
          <Alert className="errorMessage" severity="error" onClose={ ( event )=> {
            handleClose( event, props, setDisplayAlertMessage, dispatch )
          } }
          >
            <AlertTitle><ErrorMessage error={ error } manipulationType={ manipulationType }/> </AlertTitle>
          </Alert>
        </Collapse> 
        <TableContainer component={ Paper } className="data-table">
          <Table stickyHeader aria-label="simple table">
            <TableHead>          
              <TableRow >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={ selected.length > 0 && selected.length < rowCount }
                    checked={ rowCount > 0 && selected.length === rowCount }
                    onChange={ ( e )=>{
                      handleSelectAllClick( e, props, defaultRole, manipulationType, initialUserData, roles, setSelected )
                    } }
                    disabled = { manipulationType === EActionType.Edit && !initialUserData.personalDetails.isActive }
                    inputProps={ {
                      'aria-label': 'select all roles',
                    } }
                  />
                </TableCell>
                {headers.map( ( row ) => 
                  <TableCell key={ row.field }>{t( 'labels.' + row.field )}</TableCell>
                )}
              </TableRow>    
            </TableHead>
              
            <TableBody>
              {roles.map( ( row,index ) =>{
                const isItemSelected = isSelected( row[uniqueKeyForRole] ); 
                const labelId = `enhanced-table-checkbox-${index}`;
                return <TableRow
                  sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                  hover            
                  aria-checked={ isItemSelected }
                  tabIndex={ -1 }
                  key={ row[uniqueKeyForRole] }
                  selected={ isItemSelected }
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={ initialUserData.personalDetails.isActive && isItemSelected }
                      inputProps={ {
                        'aria-labelledby': labelId,
                      } }
                      onClick={ ( event ) => handleClick( event, row[uniqueKeyForRole], selected, setSelected ) }
                      disabled = { disableRole( row, manipulationType, initialUserData ) }
                    />
                  </TableCell>
                  {headers.map( ( c ) => 
                    <TableCell key={ c.field } component="th" scope="row">
                      {createCell( row,c )}                  
                    </TableCell>              
                  )}
                </TableRow>
              }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TabPanel>
    </TabContext>

    <Box className="formActionButtons">
      <Button variant="contained" size="medium" className="text-capitalize" disabled={ disableButton } onClick={ ( event )=>{
        handleSubmit( {event, props, formData, selected, initialUserData, manipulationType, t,setDisplayAlertMessage, dispatch} )
      } }
      >
        { manipulationType === EActionType.Create ? t( 'button.create' ) : t( 'button.save' ) }
      </Button>
      <Button size="medium" className="text-capitalize" onClick={ ()=> {
        resetAllErrorInfo()
        setDisplayAlertMessage( false );
      } }
      >{ t( 'button.cancel' ) } </Button>
    </Box>
  </Box>
}