import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppRouter } from '.';
import { AuthProvider } from 'oidc-react';
import { authSettings } from '../api/OidcSettings';

/**
 * Main component for authentication handling.
 * Skips authentication in developer mode
 * @returns {JSX.Element} the AppRouter component
 */
export const AuthComponent = () => {
  const navigate = useNavigate();

  const [state, setState] = React.useState( {
    configdata: {
      AuthorityUrl: '',
      ClientId: '',
      Redirect: '',
      Redirect_Silent: '',
      Developer_Mode: false,
    },
  } );

  useEffect( () => {
    setState( {
      ...state,
      configdata: authSettings,
    } );
  }, [] );

  const oidcConfig = {
    onSignIn: async () => {
      // Replace history before setting viewId so all unrelated query params are removed
      navigate( {
        pathname: '/',
      } );
    },

    authority: state.configdata.AuthorityUrl,
    clientId: state.configdata.ClientId,
    automaticSilentRenew: true,
    grant_type: 'refresh_token',
    response_type: 'code token id_token',
    redirectUri: state.configdata.Redirect,
    silent_redirect_uri: state.configdata.Redirect_Silent,
    scope: 'api://' + state.configdata.ClientId + '/User.Read email openid profile',
    loadUserInfo: false,
    revokeAccessTokenOnSignout: true,
  };

  if ( !oidcConfig.authority ) {
    return null;
  }

  if ( state.configdata.Developer_Mode ) {
    return <AppRouter />;
  }
  
  return (
    <AuthProvider { ...oidcConfig }>
      <AppRouter />
    </AuthProvider>
  );
};
