import { handleError } from '../services/DataHelperFunctions';
import { EAction, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { handleSuccess } from './HttpUtil';


export const LanguageApi = {
  
  getLanguageAssociation: ( action: { page:number, limit:number, productId?: string } ) => {
    const queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    return AxiosClient
      .post( `/product/v1/language/association/read?${queryParams}`, {productCode:action.productId ? action.productId : '' } )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total
        return {
          data: response.data,
          searchKey: action.productId,
          page: action.page,
          totalRecords: total,
          recordsPerPage:action.limit
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: action.productId,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.LanguageAssociationKey );
      } )
  },


  getLanguages: ( action: { page:number, limit:number, searchCode?: string } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.searchCode ? `${EUrlParams.Search}=` + action.searchCode + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/language/v1/read?${queryParams}`, [] )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data,
          searchKey: action.searchCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage:action.limit
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.LanguageKey );
      } )
  },


  getLanguageDetails: ( action: { request: string[] } ) => {
    const queryParams = action.searchCode ? `?${EUrlParams.Search}=` + action.searchCode || '' : '';
    return AxiosClient
      .post( '/language/v1/read' + queryParams, action.request )
      .then( ( response ) => {
        return handleSuccess( response );
      } ).catch( ( err ) => {
        return handleError( err, EAction.Get );
      } )
  },


  updateLanguageAssociation: ( action: { request } ) => {
    return AxiosClient
      .post( '/product/v1/language/association/update', action.request )
      .then( ( response ) => {
        return handleSuccess( response )  
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },


  deleteLanguage: ( action: { request } ) => {
    return AxiosClient
      .post( '/language/v1/delete', action.request )
      .then( ( response ) => {
        return handleSuccess( response )  
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },


  updateLanguage: ( action: { request } ) => {
    return AxiosClient
      .post( '/language/v1/update', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err, EAction.Update );
      } )
  },


  createLanguage: ( action: { request } ) => {
    return AxiosClient
      .post( '/language/v1/create', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err, EAction.Create );
      } )
  },

  
  deleteLanguageAssociation: ( action: { request } ) => {
    return AxiosClient
      .post( '/product/v1/language/association/delete', action.request )
      .then( ( response ) => {
        return handleSuccess( response )  
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  }
}
