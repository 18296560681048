import React from 'react';
import { useTranslation } from 'react-i18next';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { UserApi } from '../api/UserApi';
import { Button } from '@mui/material';
import { setAlertMessage } from '../store/states/AlertMessageSlice';
import { useDispatch } from 'react-redux';

export const ExportDetails = () => {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const exportData = ( ) => {
    UserApi.exportUserDetails( ).then( ( response ) => {
      if ( !response.error ) {
        const fileName = response.headers['content-disposition'].split( 'filename=' )[1];
        const url = window.URL.createObjectURL( new Blob( [response.data] , { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' } ) );
        const link = document.createElement( 'a' );
        link.href = url;
        link.setAttribute( 'download', `${fileName}` );
        document.body.appendChild( link );
        link.click();
      }else{
        dispatch( setAlertMessage( {show:true,message:t( 'messages.fail.export' ),type:'error'} ) )
      } 
    } );
  };
  return <Button variant="contained" startIcon={ <SystemUpdateAltIcon /> }
    onClick={ exportData }
    className="text-capitalize common-btn"
  >
    {t( 'button.export' )}
  </Button>    
  
}