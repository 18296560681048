import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Box,Button, TextField, Checkbox, FormControlLabel, Collapse, Alert, AlertTitle, RadioGroup, Grid, Radio, FormGroup } from '@mui/material';
import { AppDispatch, AppState, AppStore } from '../../store';
import { EActionType, EAction, RegExPatterns } from '../../data/Constants';
import { IError, IManipulationProps } from '../../../types';
import { ErrorMessage } from '../ErrorMessage';
import { resetDialogMessage } from '../../store/states/DialogSlice';
import { getApplicationDetails } from '../../store/states/ApplicationSlice';
import { createFeatureFlag, getFeatureFlagDetails, getFeatureFlags, updateFeatureFlag } from '../../store/states/FeatureFlagSlice';
import { resetAlertMessage, setAlertMessage } from '../../store/states/AlertMessageSlice';
import { resetError } from '../../store/states/ErrorSlice';
import { useDispatch, useSelector } from 'react-redux';
import { resetAllErrorInfo } from '../../services/DataHelperFunctions';

//Call apis to Create or Update the feature flags
function callCreateOrUpdateApi( manipulationType:string, props:IManipulationProps, formData, t:( message:string ) => string, dispatch: AppDispatch ) {
  if( manipulationType === EActionType.Edit ) {
    dispatch( updateFeatureFlag( {token: props.token, request: { ...formData, page:formData.page.trim(), flagName:formData.flagName.trimEnd().toUpperCase(), description:formData.description.trim() } } ) ).then( res=>{
      handleApiResponse( res.payload, props, t( 'messages.fail.update' ), t( 'messages.success.update' ), dispatch );
    } )
  }else{
    dispatch( createFeatureFlag( {token: props.token, request: { ...formData, page:formData.page.trim(), flagName:formData.flagName.trimEnd().toUpperCase(), description:formData.description.trim() }} ) ).then( res=>{
      handleApiResponse( res.payload, props, t( 'messages.fail.create' ), t( 'messages.success.create' ), dispatch );
    } )
  }
}

function handleApiResponse( res, props:IManipulationProps, failMessage:string, successMessage:string, dispatch: AppDispatch ) {
  const featureFlags = AppStore.getState().featureFlags.featureFlags;
  if( res?.data ) {
    dispatch( getFeatureFlags( {token: props.token, page: featureFlags.currentPage, limit: featureFlags.recordsPerPage, searchCode: featureFlags.searchKey, activeOnly: false } ) )
    dispatch( setAlertMessage( {show:true, message:successMessage, type:'success'} ) )
    dispatch( resetDialogMessage() );
  }else{
    const message = res?.error?.message ? res.error.message : failMessage;
    dispatch( setAlertMessage( { show:true, message:message, type:'error' } ) )
  }
}

const setApplicationDetails = async ( props, setApplicationsAvailable, dispatch: AppDispatch ) => {
  let applications = [];
  await dispatch( getApplicationDetails( {token: props.token, request: [] } ) )
    .then( res => { 
      if( res?.payload?.data ) {
        applications = res.payload.data
      }
    } )
  setApplicationsAvailable( applications );
}

const loadFormData = async ( props, setInitialFeatureFlagData, setFormData, dispatch: AppDispatch ) => {
  let featureFlagDetails = await dispatch( getFeatureFlagDetails( {token: props.token, request: [props.id]} ) ).unwrap()

  if( !featureFlagDetails?.data ) {
    return;
  }
  featureFlagDetails = featureFlagDetails.data[0];
  
  setInitialFeatureFlagData( featureFlagDetails );
  setFormData( featureFlagDetails );
}

const handleError = ( error:IError, setDisplayAlertMessage ) => {
  if( error.code && ( error.action === EAction.Create || error.action === EAction.Update ) ) {
    setDisplayAlertMessage( true );
  }  
}

const handleChange = ( event, setFormData ) => {
  const name = event.target.name;
  const value = name === 'isEditable' || name === 'isVisible' || name === 'isActive' ? event.target.checked : event.target.value;
  setFormData( values => ( {...values, [name]: value} ) )    
}

const formValidation = ( formValidationProps ) => {
  const { initialFeatureFlagData, formData,stringValidations, setDisplayNameErrorMessage, setDisplayDescriptionErrorMessage, setDisplayPageErrorMessage, setDisableButton } = formValidationProps;

  const name = formData.flagName.trimEnd();
  const page = formData.page.trimEnd();
  const description = formData.description.trimEnd();
  const validName = name.length >= stringValidations.name.min && name.length <= stringValidations.name.max && RegExPatterns.AlphaNumericSeperators.test( name );
  const validPage = page.length >= stringValidations.page.min && page.length <= stringValidations.page.max && RegExPatterns.AlphaNumericSeperators.test( page );
  const validDescription = description.length >= stringValidations.description.min && description.length <= stringValidations.description.max ;
  const validApplication = !!formData.applicationName;
  const detailsUpdated = JSON.stringify( initialFeatureFlagData ) !== JSON.stringify( {...formData,flagName:name,page,description } );
    
  setDisplayNameErrorMessage( !validName );
  setDisplayDescriptionErrorMessage( !validDescription );
  setDisplayPageErrorMessage ( !validPage );

  setDisableButton( !validName || !validDescription || !validPage || !validApplication || !detailsUpdated );
}

const renderApplications = ( applicationsAvailable, initialFeatureFlagData, formData, setFormData, t: ( msg: string )=> string ) => {
  return applicationsAvailable.length < 1 ? <p>{ t( 'messages.noApplicationsFound' )}</p> :
    <RadioGroup
      aria-labelledby="applicationName"
      name="applicationName"
      value={ formData.applicationName } 
      onChange={ ( e ) => {
        handleChange( e, setFormData )
      } }
    > <Grid container spacing={ 2 } className="pt-1">
        {applicationsAvailable.map( ( value ) => {
          if( value.isActive || initialFeatureFlagData.applicationName === value.name ) {
            return <Grid item xs={ 4 } key={ value.name } className="pt-0"> 
              <FormControlLabel value={ value.name } className="application-radio" label={ value.name } disabled={ !formData.isActive || !value.isActive } control={ < Radio /> }/>
              {!value.isActive && <span style={ {color: 'red'} }>({t( 'labels.inactive' )})</span>}
            </Grid>
          }
          return <></>
        } )
        }
      </Grid>
    </RadioGroup>
}

const renderActions = ( initialFeatureFlagData, manipulationType: string, formData, setFormData, t: ( msg: string )=> string ) => {
  return <FormGroup>
    <Grid container spacing={ 1 } >
      <Grid item xs={ 2 } key="isVisible" className="pt-0"> 
        <FormControlLabel
          value={ formData.isVisible }
          checked={ !!formData.isVisible }
          disabled={ !formData.isActive }
          control={ <Checkbox /> }
          label={ t( 'labels.isVisible' ) }
          labelPlacement="end"
          onChange={ ( e )=>{
            handleChange( e, setFormData )
          } }
          name="isVisible"
        />
      </Grid>
      <Grid item xs={ 2 } key="isEditable" className="pt-0"> 
        <FormControlLabel
          value={ formData.isEditable }
          checked={ !!formData.isEditable }
          disabled={ !formData.isActive }
          control={ <Checkbox /> }
          label={ t( 'labels.isEditable' ) }
          labelPlacement="end"
          onChange={ ( e )=>{
            handleChange( e, setFormData )
          } }
          name="isEditable"
        />
      </Grid>
      
      {!initialFeatureFlagData.isActive && manipulationType === EActionType.Edit && <Grid item xs={ 2 } key="isActive" className="pt-0"> 
        <FormControlLabel
          value={ formData.isActive }
          checked={ !!formData.isActive }
          control={ <Checkbox /> }
          label={ t( 'labels.isActive' ) }
          labelPlacement="end"
          onChange={ ( e )=>{
            handleChange( e, setFormData )
          } }
          name="isActive"
        />
      </Grid>}
    </Grid>
  </FormGroup>
}


export const FeatureFlagManipulation = ( props: IManipulationProps ) => {
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState )=> state.error )
  const [ formData, setFormData ] = useState( { 'flagName':'', 'description':'','applicationName':'', 'page':'', 'isEditable':true, 'isVisible':true, 'isActive':true } );
  const [ initialFeatureFlagData, setInitialFeatureFlagData ] = useState( { 'flagName':'', 'description':'','applicationName':'', 'page':'', 'isEditable':true, 'isVisible':true, 'isActive':true } );
  const [ applicationsAvailable, setApplicationsAvailable ] = useState( [] );
  const [ disableButton, setDisableButton ] = useState( true );
  const [ displayNameErrorMessage, setDisplayNameErrorMessage ] = useState( false );
  const [ displayDescriptionErrorMessage, setDisplayDescriptionErrorMessage ] = useState( false );
  const [ displayPageErrorMessage, setDisplayPageErrorMessage ] = useState( false );
  const [ displayAlertMessage, setDisplayAlertMessage ] = useState( false );
  const stringValidations = {
    name:{min: 3,max: 30},
    page: {min: 3,max: 50},
    description: {min: 1,max: 100}
  }

  const manipulationType = props.type; //To get the type of manipulation being performed
 
  useEffect( ()=>{
    setApplicationDetails( props, setApplicationsAvailable, dispatch );
    if( manipulationType === EActionType.Edit ) {
      loadFormData( props, setInitialFeatureFlagData, setFormData, dispatch )
    }
  },[] )

  useEffect( () => {
    handleError( error, setDisplayAlertMessage );
  }, [error] ) 

  useEffect( ()=>{
    formValidation( { initialFeatureFlagData, formData,stringValidations, setDisplayNameErrorMessage, setDisplayDescriptionErrorMessage, setDisplayPageErrorMessage, setDisableButton } );
  } )

  const handleSubmit = ( event ): void => {
    event.preventDefault();
    dispatch( resetError() ); 
    setDisplayAlertMessage( false );
    callCreateOrUpdateApi( manipulationType, props, formData, t, dispatch )
  }

  const handleClose = ( event?: React.SyntheticEvent | Event, reason?: string ) => {    
    if ( reason === 'clickaway' ) {
      return;
    }
    dispatch( resetAlertMessage() );
    dispatch( resetError() );
    setDisplayAlertMessage( false );
  };

  return <Box className="manipulationBox pt-1">     
    <Box className="manipulationForm">
      <Collapse in={ displayAlertMessage } className="show-alert">
        <Alert className="errorMessage" severity="error" onClose={ handleClose }>
          <AlertTitle><ErrorMessage error={ error } manipulationType={ manipulationType } /> </AlertTitle>
        </Alert>
      </Collapse>

      <TextField name="flagName" label={ t( 'labels.flagName' ) } required variant="outlined" size="small" disabled={ manipulationType === EActionType.Edit } fullWidth value={ formData.flagName || '' } onChange={ ( e )=>{
        handleChange( e, setFormData )
      } }
      /><br/>
      <Collapse in={ displayNameErrorMessage && formData.flagName.length > 0 }>
        <Alert className="errorMessage" severity="error">
          <AlertTitle>{t( 'messages.nameErrorMessage' )} </AlertTitle>
        </Alert>
      </Collapse>
      <br/>

      <TextField name="description" label={ t( 'labels.description' ) } required variant="outlined" size="small" disabled={ manipulationType === EActionType.Edit && !formData.isActive } fullWidth value={ formData.description || '' } onChange={ ( e )=>{
        handleChange( e,setFormData )
      } }
      /><br/>
      <Collapse in={ displayDescriptionErrorMessage && formData.description.length > 0 }>
        <Alert className="errorMessage" severity="error">
          <AlertTitle>{t( 'messages.100LimitErrorMessage' )} </AlertTitle>
        </Alert>
      </Collapse>
      <br/>

      <fieldset className="inputFieldset w-100">
        <legend className="inputLegend" id="map">{t( 'labels.applicationName' ) + ' ' }<span className="PhilipsRed">*</span></legend>
        {renderApplications( applicationsAvailable, initialFeatureFlagData, formData, setFormData, t )}
      </fieldset>
      <br/>

      <TextField name="page" label={ t( 'labels.page' ) } required variant="outlined" size="small" fullWidth disabled={ !formData.isActive } value={ formData.page || '' } onChange={ ( e )=>{
        handleChange( e, setFormData )
      } }
      /><br/>
      <Collapse in={ displayPageErrorMessage && formData.page.length > 0 }>
        <Alert className="errorMessage" severity="error">
          <AlertTitle>{t( 'messages.nameErrorMessage' )} </AlertTitle>
        </Alert>
      </Collapse>
      <br/>
      <fieldset className="inputFieldset w-100">
        <legend className="inputLegend" id="actions">{t( 'labels.actions' ) + ' ' }</legend>
        {renderActions( initialFeatureFlagData, manipulationType, formData, setFormData, t )}
      </fieldset>
      <Box className="formActionButtons">
        <Button variant="contained" size="medium" onClick={ handleSubmit } className="text-capitalize" disabled={ disableButton }>
          { manipulationType === EActionType.Create ? t( 'button.create' ) : t( 'button.save' ) }
        </Button>
        <Button size="medium" onClick={ ()=> {
          resetAllErrorInfo()
          setDisplayAlertMessage( false ); 
        } } className="text-capitalize"
        >{ t( 'button.cancel' ) } </Button>
      </Box>

    </Box>
  </Box>

}