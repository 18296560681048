import {
  Box,
  Container,
  Divider,
  Theme,
  useMediaQuery,
  Tab
} from '@mui/material';
import React, { useEffect,useState } from 'react';
import { Routes ,Route, NavLink } from 'react-router-dom';
import { Navigate } from 'react-router';
import { useAuth } from 'oidc-react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Header,
  LoadingScreen,
  Unauthenticated,
  UserManagement,
  TabDrawer,
  RoleManagement,
  ApiKeyManagement,
  ApplicationManagement,
  FeatureFlagsManagement,
  SalesText,
  Prices,
  ProductHierarchy,
  LanguageAssociation,
  Images,
  Configuration,
  Unauthorized,
  LanguageManagement,
  UnsupportedBrowser,
  PriceListMap,
  ProductCatalog,
  ProductConfiguration,
  MarketAssociation,
  MarketManagement
} from '.';

import { AppDispatch, AppState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { Footer } from './Footer';
import { AlertMessage } from './Layouts/AlertMessage';
import { CommonDialog } from './Layouts/CommonDialog';
import { tabsList }from '../data/Constants';
import { useTranslation } from 'react-i18next';
import { NotificationManagement } from './Notification/Ace/NotificationManagement';
import { PropertyManagement } from './Product/Property/PropertyManagement';
import { PropertyAssociation } from './Product/Property/PropertyAssociation';
import { setPageSize } from '../store/states/ApplicationSettingsSlice';
import { getLoggedInUserDetails } from '../store/states/UserSlice';
import { TechnicalInfo } from './DataMaintenance/TechnicalInfo';

//This function hepls to redirect the PDM Application
export const PDMApplication = () => {
  const path = window.location.href;
  return <NavLink to={ path } replace={ true } />
}
 

/**
 * Main component to handle the layout at the top level.
 * Sets the different routes.
 * Sets the current page size in the applicationSettingsState
 * @returns {JSX.Element} a container with the UI components at top level
 */

const getMiddleContent = ( token: string,user,tabValue: string,handleChange,t: ( value: string )=> string )=>{
  if( !token ) {
    return <Unauthenticated/>;
  } else if( Object.keys( user.details ).length > 0 && ( !user.details.name || !user.details.isAdmin || user.details.isService ) ) { //unauthorize user is not an admin or if user is a service user
    return <Routes>
      <Route path="*" element={ <Navigate to="/NotAuthorized" replace={ true } /> }/>
      <Route path="/NotAuthorized" element={ <Unauthorized/> } />
    </Routes>
  } else{
    return <TabContext value={ tabValue }>
      <Box sx={ { borderBottom: 1, borderColor: 'divider' } }>
        <TabList onChange={ handleChange } aria-label="Tab Menu">
          {
            tabsList.map( ( tb )=><Tab className="text-capitalize" label={ t( 'labels.' + tb.id ) } key={ tb.id } value={ tb.id } /> )
          }               
        </TabList>
      </Box>
      
      <TabPanel value="administration" className="p-0 h-100">
        <Box className="d-flex h-inherit">
          <TabDrawer tabValue={ tabValue }/>
          <Box className="middle-container">
            <Routes>
              <Route path="/NotAuthenticated" element={ <Unauthenticated/> } />

              <Route path="/pdm/signin/oidc" element={ <Navigate to="/pdm" replace={ true } /> } />
              <Route path="/pdm/signin/oidc-silent" element={ <Navigate to="/pdm" replace={ true } /> } />
              <Route path="/pdm" element= { <PDMApplication /> } />

              <Route path="/signin/oidc" element={ <Navigate to="/users" replace={ true } /> } /> 
              <Route path="/signin/oidc-silent" element={ <Navigate to="/users" replace={ true } /> } />

              <Route path="/" element= { <UserManagement token={ token } /> } />

              <Route path="/users" element={ <UserManagement token={ token } /> }/>
              <Route path="/users/*" element={ <Navigate to="/users" replace={ true } /> }/>

              <Route path="/roles" element={ <RoleManagement token={ token } /> }/>
              <Route path="/roles/*" element={ <Navigate to="/roles" replace={ true } /> }/>
  
              <Route path="/apikeys" element={ <ApiKeyManagement token={ token } /> }/>
              <Route path="/apikeys/*" element={ <Navigate to="/apikeys" replace={ true } /> }/>

              <Route path="/applications" element={ <ApplicationManagement token={ token } /> }/>
              <Route path="/applications/*" element={ <Navigate to="/applications" replace={ true } /> }/>

              <Route path="*" element={ <Navigate to="/users" replace={ true } /> }/>

            </Routes>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value="dataMaintenance" className="p-0 h-100">
        <Box className="d-flex h-inherit">
          <TabDrawer tabValue={ tabValue }/>
          <Box className="middle-container">
            <Routes>
              <Route path="/salesTexts" element={ <SalesText token={ token } /> }/>

              <Route path="/prices" element={ <Prices token={ token } /> }/>

              <Route path="/productHierarchy" element={ <ProductHierarchy token={ token } /> }/>

              <Route path="/images" element={ <Images token={ token } /> }/>

              <Route path="/priceListMap" element={ <PriceListMap token={ token } /> }/>

              <Route path="/technicalInfo" element={ <TechnicalInfo token={ token } /> }/>
              
              <Route path="*" element={ <Navigate to="/salesTexts" replace={ true } /> }/>
            </Routes>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value="product" className="p-0 h-100">
        <Box className="d-flex h-inherit">
          <TabDrawer tabValue={ tabValue }/>
          <Box className="middle-container">
            <Routes>                
              <Route path="/market" element={ <MarketManagement token={ token } /> }/>
              <Route path="/marketAssociation" element={ <MarketAssociation token={ token } /> }/>

              <Route path="/languageAssociation" element={ <LanguageAssociation token={ token } /> }/>
              <Route path="/language" element={ <LanguageManagement token={ token } /> }/>

              <Route path="/productCatalog" element={ <ProductCatalog token={ token } /> }/>
              <Route path="/productConfiguration" element={ <ProductConfiguration token={ token } /> }/>
              <Route path="/properties" element={ <PropertyManagement token={ token } /> }/>
              <Route path="/propertyAssociation" element={ <PropertyAssociation token={ token } /> }/>
              
              <Route path="*" element={ <Navigate to="/market" replace={ true } /> }/>
            </Routes>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value="featureFlags" className="p-0 h-100">
        <Box className="d-flex h-inherit">
          <TabDrawer tabValue={ tabValue }/>
          <Box className="middle-container">
            <Routes>                
              <Route path="/featureFlags" element={ <FeatureFlagsManagement token={ token } /> }/>
              <Route path="/featureFlags/*" element={ <Navigate to="/featureFlags" replace={ true } /> }/>

              <Route path="*" element={ <Navigate to="/featureFlags" replace={ true } /> }/>
            </Routes>
          </Box>
        </Box>
      </TabPanel>

      <TabPanel value="configuration">    
        <Routes>
          <Route path="/configuration" element={ <Configuration /> }/>
          <Route path="*" element={ <Navigate to="/configuration" replace={ true } /> }/>
        </Routes>
      </TabPanel>

      <TabPanel value="notification" className="p-0 h-100">
        <Box className="d-flex h-inherit">
          <TabDrawer tabValue={ tabValue }/>
          <Box className="middle-container">
            <Routes>                
              <Route path="/notification" element={ <NotificationManagement token={ token } /> }/>
              <Route path="*" element={ <Navigate to="/notification" replace={ true } /> }/>
            </Routes>
          </Box>
        </Box>
      </TabPanel>

    </TabContext>
  }
}

export const AppRouter = ( ) => {
  const {t} = useTranslation();
  const pageSize = useSelector( ( state:AppState )=> state.applicationSettings.pageSize );
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector( ( state:AppState )=> state.user )
  
  const auth = useAuth();
  const token = auth.userData?.access_token;
  const currentPageSize = {
    isExtraSmall: useMediaQuery( ( theme: Theme ) => theme.breakpoints.up( 'xs' ) && theme.breakpoints.down( 'sm' ) ),
    isSmall: useMediaQuery( ( theme: Theme ) => theme.breakpoints.up( 'sm' ) && theme.breakpoints.down( 'md' ) ),
    isMedium: useMediaQuery( ( theme: Theme ) => theme.breakpoints.up( 'md' ) && theme.breakpoints.down( 'lg' ) ),
    isLarge: useMediaQuery( ( theme: Theme ) => theme.breakpoints.up( 'lg' ) && theme.breakpoints.down( 'xl' ) ),
    isExtraLarge: useMediaQuery( ( theme: Theme ) => theme.breakpoints.up( 'xl' ) ),
  };

  useEffect( () => {
    if ( JSON.stringify( pageSize ) === JSON.stringify( currentPageSize ) ) {
      return;
    }
    dispatch( setPageSize( currentPageSize ) );
  } );
  
  const [tabValue, setTabValue] = useState<string>( 'administration' );

  const handleChange = ( ...params:[React.BaseSyntheticEvent, string] ) => {
    setTabValue( params[1] );
  };

  useEffect( () => {
    const currentTab = tabsList.find( tb=>
      tb.childs.includes( window.location.pathname.split( '/' )[1] ) 
    );
    const windowLocation = window.location.pathname.includes( 'configuration' ) ? 'configuration' : 'administration';
    setTabValue( currentTab ? currentTab.id : windowLocation );
  },[] );

  useEffect( () => {
    if( token ) {
      dispatch( getLoggedInUserDetails( {token: token} ) );
    }
  },[token] )
 
  return (
    <Container maxWidth={ false } className="appRouter root">
      <Header setTabValue={ setTabValue } />    
      <Divider className="divider" />     
      <Box className="appRouterContent">
        <Box sx={ { width: '100%', typography: 'body1' } }>
          {getMiddleContent( token,user,tabValue,handleChange,t )}
        </Box>
      </Box>
      <LoadingScreen />
      <Footer />
      <AlertMessage/>
      <CommonDialog/>
      <UnsupportedBrowser/>
    </Container>
  );
}
