import React from 'react';
import { useTranslation } from 'react-i18next';
import { IManagementProps } from '../../../types';
import { FileUpload } from './FileUpload';
import { EDataMaintenanceType } from '../../data/Constants';
import { useSelector } from 'react-redux';
import { AppState } from 'store';

/**
 * Technical Info component
 * @param {IManagementProps} props Management props
 * @returns {React.JSX.Element} JSX element for Technical information
 */
export const TechnicalInfo = ( props: IManagementProps ) => {
  const {t} = useTranslation();
  const messages = useSelector( ( state: AppState )=> state.technicalInfo.messages )

  return <>  
    <div className="d-flex">
      <h3 className="mainHeader technical-info"> {t( 'header.technicalInfo' )} </h3>
      <FileUpload 
        token={ props.token } 
        type = { EDataMaintenanceType.TechnicalInfo }
      />
    </div>
    <br/>
    <ul>
      {messages.map( ( message )=>{
        return <li key = { message }> {message}</li>
      } )}
    </ul>
  </>
}