import { handleError } from '../services/DataHelperFunctions';
import { EAction, EActivity, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { handleSuccess } from './HttpUtil';


export const ProductHierarchyApi = {
  
  importProductHierarchy: ( action: { request } ) => {
    return AxiosClient
      .post( '/product/v1/hierarchy/create/file', {file: action.request} )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },
     

  getProductHierarchy: ( action: { page:number, limit:number, searchCode?: string } ) => {
    const requestBody = action.searchCode ? action.searchCode : '';
    return AxiosClient
      .post( `/product/v1/hierarchy/read?${EUrlParams.Page}=${action.page}&${EUrlParams.Limit}=${action.limit}`,
        { search: requestBody } )
      .then( ( response ) => {      
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data,
          searchKey: action.searchCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.ProductHierarchyKey );
      } )
  },


  compareProductHierarchy: ( action: { page:number, limit:number, searchCode?: string } ) => {
    const requestBody = action.searchCode ? action.searchCode : '';
    const queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    return AxiosClient
      .post( `/product/v1/hierarchy/compare?${queryParams}`, { search: requestBody } )
      .then( ( response ) => {      
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data,
          searchKey: action.searchCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit,
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1,
          }
        }
        return handleError( err, EAction.Read, EApiType.CompareProductHierarchyKey );
      } )
  },
    

  publishProductHierarchy: ( action: { activity:EActivity } ) => {
    return AxiosClient
      .post( '/product/v1/hierarchy/publish', {activity: action.activity} )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  }    
}