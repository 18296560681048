import { Dialog, DialogTitle, DialogContent, IconButton, Button, DialogActions, Grid, FormControlLabel, Checkbox, Box, Tab } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { AppDispatch, AppState } from '../../store';
import { useTranslation, Trans } from 'react-i18next';
import { ECommonDialogType, ETabValue, EDataMaintenanceType, EDataTableType, foreignKeysForTable, EActionType, EModuleType, ECellType, ECellSubType } from '../../data/Constants';
import { LanguageAssociationManipulation } from '../Product/Language/LanguageAssociationManipulation';
import { MarketAssociationManipulation } from '../Product/Market/MarketAssociationManipulation';
import { UserManipulation } from '../Administration/Users/UserManipulation';
import { RoleManipulation } from '../Administration/Roles/RoleManipulation';
import { ApiKeyManipulation } from '../Administration/ApiKeys/ApiKeyManipulation';
import { LanguageManipulation }from '../Product/Language/LanguageManipulation';
import { MarketManipulation }from '../Product/Market/MarketManipulation';
import { PriceListMapManipulation } from '../DataMaintenance/PriceListMap/PriceListMapManipulation';
import { DetailedView } from './DetailedView';
import { useAuth } from 'oidc-react';
import parse from 'html-react-parser';
import * as DOMPurify from 'dompurify';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import WarningIcon from '@mui/icons-material/Warning';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ApplicationManipulation } from '../Administration/Applications/ApplicationManipulation';
import { FeatureFlagManipulation } from '../FeatureFlags/FeatureFlagsManipulation';
import { ProductHierarchyCompare } from '../DataMaintenance/ProductHierarchy/ProductHierarchyCompare';
import DraggableComponent from '../DraggableComponent';
import { DetailsDialog } from './DetailsDialog';
import CancelIcon from '@mui/icons-material/Cancel';
import { PropertyManipulation } from '../Product/Property/PropertyManipulation';
import { FeaturePropertyAssociation } from '../Product/Property/FeaturePropertyAssociation';
import { resetDialogMessage } from '../../store/states/DialogSlice';


const LongSalesTextContent = ( longSalesTextProps ) => {
  const { message,handleChange, tabValue, t } = longSalesTextProps;
  let longSaleText = null;
  try {
    longSaleText = message.longSalesText ? JSON.parse( message.longSalesText ) : message.longSalesText;
  } catch {
    longSaleText = message.longSalesText;
  }
  return <TabContext value={ tabValue || ETabValue.ShortSalesText }>
    <Box className="dialog-box">
      <TabList onChange={ handleChange } >
        <Tab className="dialog-tab text-capitalize"
          value={ ETabValue.ShortSalesText } label={ t( 'labels.shortSalesText' ) }
        />
        <Tab className="dialog-tab text-capitalize" 
          value={ ETabValue.LongSalesText } label={ t( 'labels.longSalesText' ) }
        />          
      </TabList>
    </Box>
    <TabPanel className="dialog-panel pt-1" value={ ETabValue.ShortSalesText } >
      { message.shortSalesText}
    </TabPanel>
    <TabPanel className="dialog-panel pt-1 " value={ ETabValue.LongSalesText }>
      { parse( DOMPurify.sanitize( longSaleText ) ) }
    </TabPanel>       
  </TabContext>
}

const ApiKeyCreateSuccess = ( apiKeyCreateSuccessProps ) => {
  const { apiKey, disableButton, enableButton, t } = apiKeyCreateSuccessProps;
  const copyText = ( )=>{
    navigator.clipboard.writeText( apiKey );
  }
  return <>    
    <div className="d-flex align-center pb-1"><b>{t( 'labels.apiKey' )}: &nbsp;</b>
      <span className="apiKey" >{apiKey}</span>
      <IconButton aria-label="close" onClick={ ()=>copyText() }>
        <ContentCopyIcon />
      </IconButton>
    </div>
    <FormControlLabel
      value={ disableButton }
      checked={ !disableButton }
      control={ <Checkbox /> }
      label={ t( 'labels.copyApiKey' ) }
      labelPlacement="end"
      onChange={ enableButton }
      name="copyKey"
    />
    <br/>
    <b>{t( 'labels.note' )}: </b>{t( 'messages.copyApiKeyMessage' )}
  </>
}

function getHeader( manipulationType:string,t:( arg: string, arg2?: object ) => string,type:string,name:string ) {
  if( manipulationType === EActionType.Delete ) {
    return <Grid container alignItems="center">
      <><WarningIcon className="warningIcon"/> &nbsp;</> 
      <span > {t( 'labels.deleteConfirm' )} </span>
    </Grid> 
  }else{
    return manipulationType === EActionType.Create ? t( 'labels.create' ) + ' ' + t( 'header.' + type ) : t( 'labels.edit' ) + ' ' + t( 'header.' + type ) + ' (' + name + ')';
  }
}

//update the method for reduce cognitive complexities
const getDialogTitle = ( dialogData,t )=>{
  switch( dialogData.type ) {
    case ECommonDialogType.ApiKeyCreateSuccess:
      return <Grid container alignItems="center">
        <><CheckCircleIcon className="successIcon"/> &nbsp;</> 
        <span >  {t( 'dialogBox.success' )}</span>
      </Grid>
    case EDataMaintenanceType.PriceListMap:
      return `${t( 'labels.editPriceListMap' )} (${dialogData.message.country})`;
    case EDataMaintenanceType.LanguageAssociation:
      return `${t( 'labels.editLanguageAssociation' )} (${dialogData.message.productCode})`;
    case EDataMaintenanceType.MarketAssociation:
      return `${t( 'labels.editMarketAssociation' )} (${dialogData.message.productCode})`;
    case ECommonDialogType.Image:
      return `${t( 'labels.image' )} (${dialogData.message.name})`;
    case ECommonDialogType.LongSalesText:
      return `${t( 'header.salesText' )} (${dialogData.message.materialCode})`;
    case ECommonDialogType.ShortSalesText:
      return `${t( 'header.salesText' )} (${dialogData.message.materialCode})`;
    case ECommonDialogType.Languages:
      return getHeader( dialogData.manipulationType,t, dialogData.type,dialogData.message.name );
    case ECommonDialogType.Markets:
      return getHeader( dialogData.manipulationType,t, dialogData.type,dialogData.message.name );
    case ECommonDialogType.Error:
      return <><CancelIcon color= "error"/>&nbsp;{t( 'dialogBox.error' )}</>;
    case ECommonDialogType.Ace:
      return t( 'header.notification' )
    case ECommonDialogType.Properties:
      return getHeader( dialogData.manipulationType,t, dialogData.type,dialogData.message.name );
    case ECommonDialogType.PropertyAssociation:
      return `${t( 'header.featurePropertyAssociation' )} ( ${dialogData.message.productCode} )`;
    default:
      return getDialogTitleByManipulationType( dialogData,t );
  }
}

const getDialogTitleByManipulationType = ( dialogData,t )=>{
  switch( dialogData.manipulationType ) {
    case EActionType.Compare:
      return `${t( 'labels.compare' )}`;
    case EActionType.View:
      return '' + t( 'header.' + dialogData.header.detailedViewHeader ) + ' (' + dialogData.message[dialogData.uniqueKey] + ')';
    default:
      return getDialogTitleByModuleType( dialogData,t );
  }
}

const getDialogTitleByModuleType = ( dialogData, t ) => {
  switch( dialogData.moduleType ) {
    case EModuleType.Administration:
    case EModuleType.Datamaintainance:
      return getHeader( dialogData.manipulationType,t, dialogData.type,dialogData.message.name );
    case EModuleType.FeatureFlags:
      return getHeader( dialogData.manipulationType,t, dialogData.type,dialogData.message.flagName );

    default:
      return 'dialog';
  }
}


const getDialogActions = ( dialogData, closeDialog, disableButton, t )=>{
  if( dialogData.type === ECommonDialogType.ApiKeyCreateSuccess ) {
    return <Button onClick={ closeDialog } disabled={ disableButton } >{t( 'button.ok' )}</Button>
  }else if( dialogData.type === ECommonDialogType.EditLanguageAssociation ||
            dialogData.type === ECommonDialogType.EditMarketAssociation || 
            dialogData.type === ECommonDialogType.EditPriceListMap || 
            dialogData.type === EDataMaintenanceType.ProductHierarchy ) {
    return null
  }else if( dialogData.manipulationType === EActionType.Delete ) {
    return <><Button className="text-capitalize" onClick={ closeDialog }>{t( 'button.cancel' )}</Button>
      <Button onClick={ dialogData.callDeleteAPI } >{t( 'button.ok' )}</Button></>
  }else{
    return <Button onClick={ closeDialog } >{t( 'button.ok' )}</Button>;
  }
}

const CommonDialogContent = ( commonDialogContentProps ) => {
  const { dialogData, token, uniqueKey, t, handleChange, tabValue, disableButton, enableButton } = commonDialogContentProps;
  if( dialogData.manipulationType === EActionType.Delete ) {
    const items = dialogData.message.selectedIds.join( ', ' );
    const message = t( 'messages.deleteConfirmation' );
    if( dialogData.type === EDataTableType.Applications ) {
      return <Trans i18nKey={ 'messages.confirmation.delete.applications' } values={ { applicationNames:items } } />
    } else if( dialogData.type === EDataTableType.FeatureFlags ) {
      return <Trans i18nKey={ 'messages.confirmation.delete.featureFlags' } values={ { featureFlagNames:items } } />
    } else if( dialogData.type === EDataTableType.Prices ) {
      return <Trans i18nKey={ t( 'messages.deletePricesConfirmation' ) } values={ { featureFlagNames:items } } />
    } else{
      return <div className="pt-1" >{ message }</div>;
    }
  } else{
    switch( dialogData.type ) {        
      case EDataTableType.Users:
        return <UserManipulation token={ token } id={ dialogData.message[uniqueKey] } type={ dialogData.manipulationType } />;
      case EDataTableType.Roles:
        return <RoleManipulation token={ token } id={ dialogData.message[uniqueKey] } type={ dialogData.manipulationType } />;
      case EDataTableType.ApiKeys:
        return <ApiKeyManipulation token={ token } id={ dialogData.message[uniqueKey] } type={ dialogData.manipulationType } />;
      case EDataTableType.Applications:
        return <ApplicationManipulation token={ token } id={ dialogData.message[uniqueKey] } type={ dialogData.manipulationType } />;
      case EDataTableType.PriceListMap :
        return <PriceListMapManipulation token={ token } id={ dialogData.message[uniqueKey] } type={ dialogData.manipulationType } />
      case EDataTableType.Markets :
        return <MarketManipulation token={ token } id={ dialogData.message[uniqueKey] } type={ dialogData.manipulationType } />
      case EDataTableType.MarketAssociation :
        return <MarketAssociationManipulation token={ token } data={ dialogData.message } />
      case EDataTableType.Languages :
        return <LanguageManipulation token={ token } id={ dialogData.message[uniqueKey] } type={ dialogData.manipulationType } />
      case EDataTableType.LanguageAssociation :
        return <LanguageAssociationManipulation token={ token } data={ dialogData.message } />
      case EDataTableType.FeatureFlags:
        return <FeatureFlagManipulation token={ token } id={ dialogData.message[uniqueKey] } type={ dialogData.manipulationType } />;
      case ECommonDialogType.LongSalesText :
        return <LongSalesTextContent message={ dialogData.message } tabValue={ tabValue } handleChange={ handleChange }t={ t }/>
      case ECommonDialogType.Image:
        return <div className="text-center"><img src={ dialogData.message.url } alt="message-url"/></div>;
      case ECommonDialogType.ApiKeyCreateSuccess:
        return <ApiKeyCreateSuccess apiKey={ dialogData.message } enableButton={ enableButton } disableButton={ disableButton } t={ t }/>
      case EDataMaintenanceType.ProductHierarchy:
        return <ProductHierarchyCompare token={ token } />;
      case ECommonDialogType.Error:
        return <div className="pt-1">{dialogData.message}</div>;
      case ECommonDialogType.Ace:
        return <div className="pt-1">{dialogData.message.data}</div>;
      case ECommonDialogType.Properties:
        return <PropertyManipulation token={ token } id={ dialogData.message[uniqueKey] } type={ dialogData.manipulationType } />
      case ECommonDialogType.PropertyAssociation:
        return <FeaturePropertyAssociation productId={ dialogData.message.productCode } token={ token } />
      default:
        return <div className="pt-1" >Default div</div>;
    }
  }
}

const getDialogContent = ( dialogData, disableButton, enableButton, t, handleChange, tabValue, token )=>{  
  const uniqueKey = foreignKeysForTable[dialogData.type];
  if( dialogData.type === ECellType.ArrayChips ) {
    return DetailedView( dialogData.message[dialogData.header.field], dialogData.header.field, t )
  } else if( dialogData.type === ECellType.ObjectChips ) {
    switch ( dialogData.header.subType ) {
      case ECellSubType.Roles:
        return DetailedView( dialogData.message.roles, dialogData.header.field, t )
      case ECellSubType.Countires:
        return DetailedView( dialogData.message.countries, dialogData.header.field, t, true )
      case ECellSubType.Languages:
        return DetailedView( dialogData.message.languages, dialogData.header.field, t, true )        
    }
  } else if( dialogData.type === ECellType.Details ) {
    switch ( dialogData.header.subType ) {
      case ECellSubType.AssociatedMarkets:
        return DetailsDialog( dialogData.message.markets, dialogData.detailsViewType, t, true )
      case ECellSubType.AssociatedLanguages:
        return DetailsDialog( dialogData.message.languages, dialogData.detailsViewType, t, true )  
      case ECellSubType.Images:
        return DetailsDialog( dialogData.message.materialImages, dialogData.detailsViewType, t, true )  
      
    }
  } else{
    return CommonDialogContent( { dialogData, token, uniqueKey, t, handleChange, tabValue, disableButton, enableButton } );
  }
  return <></>
}

const largerDialog = ( dialogData ) => {
  return dialogData.manipulationType === EActionType.View && dialogData.type !== ECellType.Details || 
                                              ( dialogData.type === EDataTableType.ApiKeys || 
                                                dialogData.type === EDataTableType.FeatureFlags || 
                                                dialogData.type === EDataTableType.MarketAssociation || 
                                                dialogData.type === EDataTableType.LanguageAssociation || 
                                                dialogData.type === EDataTableType.PriceListMap || 
                                                dialogData.type === EDataMaintenanceType.ProductHierarchy ||
                                                dialogData.type === EDataTableType.Properties 
                                              ) && 
                                                ( dialogData.manipulationType === EActionType.Compare || 
                                                  dialogData.manipulationType === EActionType.Create || 
                                                  dialogData.manipulationType === EActionType.Edit );
}

const xLargerDialog = ( dialogData ) => {
  return dialogData.manipulationType === EActionType.View && dialogData.type === ECellType.Details ||
  dialogData.type === EDataTableType.PropertyAssociation 
}

export const CommonDialog = () => {
  const auth = useAuth();
  const token = auth?.userData?.access_token;
  const [ disableButton, setDisableButton ] = useState( true );
  const [ tabValue, setTabValue ] = useState<string|null>( 'ShortSalesText' );
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const dialogData = useSelector( ( state:AppState ) => state.dialogData )


  const handleChange = ( ...params:[React.SyntheticEvent, string] ) => {
    setTabValue( params[1] );
  };

  useEffect( ()=>{
    if( dialogData.type === ECommonDialogType.LongSalesText || dialogData.type === ECommonDialogType.ShortSalesText ) {
      setTabValue( dialogData.type );
    }
  } ,[dialogData.type] )

  if( !dialogData.show ) {
    return null;
  }
 
  const closeDialog = ()=>{
    setDisableButton( true );
    dispatch( resetDialogMessage() );
  }

  const enableButton = ( event )=>{
    setDisableButton( !event.target.checked );
  }

  return <Dialog open={ dialogData.show } 
    aria-labelledby="draggable-dialog-title" 
    PaperComponent={ DraggableComponent } 
    className={ `common-dialog-style success-dialog 
    ${dialogData.type !== ECommonDialogType.ApiKeyCreateSuccess 
      && dialogData.manipulationType !== EActionType.Delete
      && dialogData.manipulationType !== ECommonDialogType.Error ? 'manipulationDialog' : ''} 
      ${ largerDialog( dialogData ) ? 'largerManipulationDialog' : ''} 
      ${ xLargerDialog( dialogData ) ? 'xLargerDialog' : ''}` }
  >

    <DialogTitle className="header">
      {getDialogTitle( dialogData,t )}
    </DialogTitle>

    <DialogContent className="pb-0 align-center ">
      {getDialogContent( dialogData,disableButton,enableButton,t,handleChange,tabValue,token )}
    </DialogContent>

    <DialogActions >
      {getDialogActions( dialogData,closeDialog,disableButton,t )}
    </DialogActions>

  </Dialog> 
}