import { createAsyncThunk } from '@reduxjs/toolkit';
import { MarketApi } from '../../api/MarketApi';
import { DataMaintenanceApi } from '../../api/DataMaintenanceApi';

//Market Thunks

//To get all markets for Price list map create and update
export const getCountryList = createAsyncThunk( 'market/getCountryList', async ()=>{
  return DataMaintenanceApi.getCountryList();
} )

//To get paginated market details
export const getMarkets = createAsyncThunk( 'market/getMarkets', async ( action:{ page:number, limit:number, searchCode?: string } )=>{
  return MarketApi.getMarkets( action );
} )

//To get a market details
export const getMarketDetails = createAsyncThunk( 'market/getMarketDetails', async ( action:{ request: string[] } )=>{
  return MarketApi.getMarketDetails( action );
} )
 
//To update a market
export const updateMarket = createAsyncThunk( 'market/updateMarket', async ( action:{ request } )=>{
  return MarketApi.updateMarket( action );
} )

//To create a market
export const createMarket = createAsyncThunk( 'market/createMarket', async ( action:{ request } )=>{
  return MarketApi.createMarket( action );
} )

//To delete market(s)
export const deleteMarket = createAsyncThunk( 'market/deleteMarket', async ( action:{ request } )=>{
  return MarketApi.deleteMarket( action );
} )
  
//Market Association Thunks
//To get paginated market association data
export const getMarketAssociation = createAsyncThunk( 'market/getMarketAssociation', async ( action:{ page:number, limit:number, searchCode?: string } )=>{
  action.productId = action.searchCode;
  return MarketApi.getMarketAssociation( action );
} )
 
//To update market association data
export const updateMarketAssociation = createAsyncThunk( 'market/updateMarketAssociation', async ( action:{ request } )=>{
  return MarketApi.updateMarketAssociation( action );
} )

//To delete market association data
export const deleteMarketAssociation = createAsyncThunk( 'market/deleteMarketAssociation', async ( action:{ request } )=>{
  return MarketApi.deleteMarketAssociation( action );
} )
  
  