import React, { useEffect, useState } from 'react';
import { Grid,TextField,Box,Button,DialogTitle,Dialog,IconButton,DialogContent,InputLabel,Select,MenuItem,FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { appSettings } from '../settings';
import { ECommonDialogType, EUrlParams, IFrameTitle } from '../data/Constants';
import CloseIcon from '@mui/icons-material/Close';
import { ProductAssociationApi } from '../api/ProductAssociationApi';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { setDialogMessage } from '../store/states/DialogSlice';

export const Configuration = ( ) => {
  const {t} = useTranslation();
  const [formData, setFormData] = useState( { 'configurationId':'', 'apiKey':'', 'language':'', 'country':'', 'token':'', 'headerLess':true } );
  const [showDialog, setShowDialog] = useState( false );
  const [disableButton, setDisableButton] = useState( false );
  const [configurationName, setConfigurationName] = useState( '' );

  
  const dispatch = useDispatch<AppDispatch>();
  
  const handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormData( values => ( {...values, [name]: value} ) )
  }

  const openDialog = ( ) => {
    ProductAssociationApi.getProductConfiguration( {token:formData.token,page:1,limit:15,searchCode:formData.configurationId} )
      .then( ( response ) => {
        if( response.data.length === 0 ) {
          dispatch( setDialogMessage( {show:true, message: t( 'messages.fail.configurationId', {id:formData.configurationId} ) ,type:ECommonDialogType.Error, manipulationType:ECommonDialogType.Error } ) );
        } else{
          setConfigurationName( response.data[0].name )
          setShowDialog( true );
        }
      } )
  }

  const closeDialog = ()=>{
    setShowDialog( false );
  }

  const clearForm = () =>{
    setFormData( { 'configurationId':'', 'apiKey':'', 'language':'', 'country':'', 'token':'', 'headerLess':true } )
  }

  const openInNewTab = ()=>{
    const URL = appSettings.ConfiguratorEndpoint + `/?${EUrlParams.ConfigurationId}=` + formData.configurationId + `&${EUrlParams.ApiKey}=` + formData.apiKey + `&${EUrlParams.Token}=` + formData.token + `&${EUrlParams.Language}=` + formData.language + `&${EUrlParams.Country}=` + formData.country + `&${EUrlParams.HeaderLess}=` + formData.headerLess;
    window.open( URL, '_blank' );
  }

  useEffect( ()=>{
    setDisableButton( !formData.configurationId || !formData.apiKey || !formData.token ); //To disable the buttons
  } ) 

  return <><Grid container spacing={ 2 } data-testid="configuration-tab">
    <Grid item xs={ 6 }>
      <TextField name="configurationId" data-testid="configuration-id" label={ t( 'labels.configurationId' ) } variant="outlined" size="small" fullWidth value={ formData.configurationId } onChange={ handleChange } /><br/>
      <TextField name="apiKey" label={ t( 'labels.apiKey' ) } variant="outlined" size="small" fullWidth value={ formData.apiKey } onChange={ handleChange } className="mt-1em"/><br/>
      <TextField name="language" label={ t( 'labels.language' ) } variant="outlined" size="small" fullWidth value={ formData.language } onChange={ handleChange } className="mt-1em"/><br/>
      <TextField name="country" label={ t( 'labels.country' ) } variant="outlined" size="small" fullWidth value={ formData.country } onChange={ handleChange } className="mt-1em"/><br/>
      <TextField name="token" label={ t( 'labels.token' ) } variant="outlined" size="small" fullWidth value={ formData.token } onChange={ handleChange } className="mt-1em"/><br/><br/>
      <FormControl className="w-100" size="small" >
        <InputLabel>{t( 'labels.headerLess' )}</InputLabel>
        <Select color="primary" label={ t( 'labels.headerLess' ) } value={ formData.headerLess }
          onChange={ handleChange } name="headerLess"
        >
          <MenuItem value={ true } key={ true } >True</MenuItem> 
          <MenuItem value={ false } key={ false } >False</MenuItem> 
        </Select>
      </FormControl>
      <br/>
      <br/>
      <Box className="text-right">
        <Button size="medium" onClick={ clearForm } className="text-capitalize" >{ t( 'button.clear' ) } </Button> &emsp;
        <Button size="medium" onClick={ openDialog } variant="contained" disabled={ disableButton } className="text-capitalize" >{ t( 'button.iframe' ) } </Button> &emsp;
        <Button size="medium" onClick={ openInNewTab } variant="contained" disabled={ disableButton } className="text-capitalize" >{ t( 'button.newTab' ) } </Button>
      </Box>
    </Grid>
  </Grid>

  <Dialog open={ showDialog } aria-labelledby="draggable-dialog-title" className="iframeDialogStyle">
    <DialogTitle className="header">{configurationName}({ formData.configurationId })
      <IconButton aria-label="close" onClick={ closeDialog } className="closeIcon">
        <CloseIcon />
      </IconButton>
    </DialogTitle>

    <DialogContent sx={ {paddingTop:'1em'} } >
      <iframe 
        src={ appSettings.ConfiguratorEndpoint + `/?${EUrlParams.ConfigurationId}=` + formData.configurationId + `&${EUrlParams.ApiKey}=` + formData.apiKey + `&${EUrlParams.Token}=` + formData.token + `&${EUrlParams.Language}=` + formData.language + `&${EUrlParams.Country}=` + formData.country } 
        title= { IFrameTitle }
      />
    </DialogContent>
  </Dialog>
  </>
}