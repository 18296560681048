import { handleError } from '../services/DataHelperFunctions';
import { EAction,EApiType,EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { handleSuccess } from './HttpUtil';


export const ApiKeyApi = {
  
  getApiKeys: ( action : { page:number, limit:number, searchCode?: string } )=>{
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.searchCode ? `${EUrlParams.Search}=` + action.searchCode + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/apikey/v1/read/all?${queryParams}`, {} )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data,
          searchKey: action.searchCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit
        } 
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err,EAction.Read,EApiType.ApiKey );
      } )
  },


  createApiKey: ( action : { request } )=>{
    return AxiosClient
      .post( '/apikey/v1/create', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err,EAction.Create );
      } )
  },


  updateApiKey: ( action : { request } )=>{
    return AxiosClient
      .post( '/apikey/v1/update', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err,EAction.Update ) ;
      } )
  },


  deleteApiKeys: ( action : { request } )=>{
    return AxiosClient
      .post( '/apikey/v1/delete', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },


  getApiKeyDetails: ( action : { id:string } )=>{
    return AxiosClient
      .post( '/apikey/v1/read', {id:action.id} )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err, EAction.Get );
      } )
  },


  getServiceUsers: () => {
    return AxiosClient
      .post( '/user/v1/service/read', [] )
      .then( ( response ) => {
        return handleSuccess( response )
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },


  //Get the Configit Property list
  getConfigitPropertyList: () => {
    return AxiosClient
      .get( '/ace/library/v1/properties/read' )
      .then( ( response ) => {
        return handleSuccess( response )
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },
 
  
  //Get the PDM Property list
  getPdmPropertyList: () => {
    return AxiosClient
      .post( '/property/v1/read', {} )
      .then( ( response ) => {
        return {
          data: response.data.properties
        }
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },
 
 
  getPrivileges: () => {
    return AxiosClient
      .get( '/privilege/v1/read/all' )
      .then( ( response ) => {
        return handleSuccess( response )
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  }
}
