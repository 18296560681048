import { handleError } from '../services/DataHelperFunctions';
import { EAction, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { handleSuccess } from './HttpUtil';


export const PriceListMapApi = {
  
  getPriceListMap: ( action: { page:number, limit:number, searchCode?: string } ) => {
    const queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    return AxiosClient
      .post( `/price/v1/pricelist/read?${queryParams}`, {criteria:action.searchCode ? action.searchCode : ''} )
      .then( ( response ) => {      
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data.priceMaps,
          searchKey: action.searchCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.PriceListMapKey );
      } )
  },
 
  
  getPriceListMapDetails: ( action: { searchCode: string } ) => {
    return AxiosClient
      .post( '/price/v1/pricelist/read', {criteria:action.searchCode ? action.searchCode : ''} )
      .then( ( response ) => {
        return handleSuccess( response );
      } ).catch( ( err ) => {
        return handleError( err, EAction.Get );
      } )
  },
  
 
  updatePriceListMap:( action:{ request } )=>{
    return AxiosClient
      .post( '/price/v1/pricelist/create', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err, EAction.Update );
      } )
  }
}