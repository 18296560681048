import { handleError } from '../services/DataHelperFunctions';
import { EAction, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { handleSuccess } from './HttpUtil';


export const MarketApi = {
  
  getMarkets: ( action: { page:number, limit:number, searchCode?: string } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.searchCode ? `${EUrlParams.Search}=` + action.searchCode + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/market/v1/read?${queryParams}`, [] )
      .then( ( response ) => {      
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data,
          searchKey: action.searchCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.MarketKey );
      } )
  },
    

  getMarketDetails: ( action: { request: string[] } ) => {
    return AxiosClient
      .post( '/market/v1/read', action.request )
      .then( ( response ) => {      
        return handleSuccess( response );
      } ).catch( ( err ) => {
        return handleError( err, EAction.Get );
      } )
  },
    

  createMarket: ( action: { request } ) => {
    return AxiosClient
      .post( '/market/v1/create', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err, EAction.Create );
      } )
  },
  

  updateMarket: ( action: { request } ) => {
    return AxiosClient
      .post( '/market/v1/update', action.request )
      .then( ( response ) => {
        return handleSuccess( response )  
      } ).catch( ( err ) => {
        return handleError( err, EAction.Update );
      } )
  },
    

  deleteMarket: ( action: { request } ) => {
    return AxiosClient
      .post( '/market/v1/delete', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },
    

  getMarketAssociation: ( action: { page:number, limit:number, productId?: string } ) => {
    const queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    return AxiosClient
      .post( `/product/v1/market/association/read?${queryParams}`, {productCode:action.productId ? action.productId : '' } )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total
        return {
          data: response.data,
          searchKey: action.productId,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: action.productId,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.MarketAssociationKey );
      } )
  },
    

  updateMarketAssociation: ( action: { request } ) => {
    return AxiosClient
      .post( '/product/v1/market/association/update', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },
    

  deleteMarketAssociation: ( action: { request } ) => {
    return AxiosClient
      .post( '/product/v1/market/association/delete', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  }
}