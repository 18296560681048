import { handleError } from '../services/DataHelperFunctions';
import { EAction, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { handleSuccess } from './HttpUtil';


export const UserApi = {

  getUsers: ( action: { page:number, limit:number, searchCode?: string } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.searchCode ? `${EUrlParams.Search}=` + action.searchCode + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/user/v1/read?${queryParams}`, [] )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data,
          searchKey: action.searchCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: action.searchCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.UserKey );
      } )
  },


  createUser: ( action: { request } ) => {
    return AxiosClient
      .post( '/user/v1/role/create', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err,EAction.Create );
      } )
  },


  updateUser: ( action: { request } ) => {
    return AxiosClient
      .post( '/user/v1/update', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err,EAction.Update );
      } )
  },


  updateUserRoleAssociation: ( action: { request } ) => {
    return AxiosClient
      .post( '/role/v1/user/association/update', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err,EAction.Update );
      } )
  },


  deleteUsers: ( action: { request } ) => {
    return AxiosClient
      .post( '/user/v1/delete', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },


  exportUserDetails: () => {
    return AxiosClient
      .get( '/user/v1/info/export', { headers:{responseType: 'arraybuffer' } } )
      .then( ( response ) => {
        return response;
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },


  getUserDetails: ( action: { request } ) => {
    return AxiosClient
      .post( '/user/v1/read', action.request )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err, EAction.Get );
      } )
  },


  getLoggedInUserDetails: () => {
    return AxiosClient
      .get( '/user/v1/details' )
      .then( ( response ) => {
        return handleSuccess( response )
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  }

}
