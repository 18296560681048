
import { handleError } from '../services/DataHelperFunctions';
import { EAction, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { IPaginationRequest } from '../../types/IApiTypes';


export const NotificationApi = {

  //get all notifications from the api
  getNotifications: ( payload: IPaginationRequest ) => {
    let queryParams = `${EUrlParams.Page}=` + payload.page + `&${EUrlParams.Limit}=` + payload.limit;
    queryParams = ( payload.searchCode ? `${EUrlParams.Search}=` + payload.searchCode + '&' : '' ) + queryParams;
    return AxiosClient
      .post( `/notification/v1/read?${queryParams}`,{} )
      .then( ( response ) => {
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data,
          searchKey: payload.searchCode,
          page: payload.page,
          totalRecords: total,
          recordsPerPage: payload.limit
        }
      } ).catch( ( err ) => {
        if ( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: payload.searchCode,
            page: payload.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.NotificationKey );
      } )
  }
}