import { handleError } from '../services/DataHelperFunctions';
import { EAction, EApiType, EUrlParams } from '../data/Constants';
import AxiosClient from './AxiosClient';
import { handleSuccess } from './HttpUtil';


export const SalesTextApi = {

  importSalesText: ( action: { request } ) => {
    return AxiosClient
      .post( '/salestext/v1/create/file', {salesTextXmlZip: action.request} )
      .then( ( response ) => {
        return handleSuccess( response ) 
      } ).catch( ( err ) => {
        return handleError( err );
      } )
  },
  
  
  getSalesText: ( action: { page:number, limit:number, materialCode?: string } ) => {
    let queryParams = `${EUrlParams.Page}=` + action.page + `&${EUrlParams.Limit}=` + action.limit;
    queryParams = ( action.materialCode ? `${EUrlParams.MaterialCode}=` + action.materialCode + '&' : '' ) + queryParams;
    
    return AxiosClient
      .get( `/salestext/v1/read?${queryParams}` )
      .then( ( response ) => {      
        const total = JSON.parse( response.headers['x-pagination'] ).Total;
        return {
          data: response.data,
          searchKey: action.materialCode,
          page: action.page,
          totalRecords: total,
          recordsPerPage: action.limit
        }
      } ).catch( ( err ) => {
        if( err.response.status === 404 || err.response.status === 400 ) { //To set the state data as empty array so that UI doesn't break and display proper error message
          return {
            data: [],
            searchKey: action.materialCode,
            page: action.page,
            totalRecords: 1
          }
        }
        return handleError( err, EAction.Read, EApiType.SalesTextKey );
      } )
  },
}