import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppStore, AppDispatch, AppState } from '../../../store';
import { Box,Button, TextField,Checkbox,FormControlLabel, Collapse, Alert, AlertTitle } from '@mui/material';
import { ErrorMessage } from '../../ErrorMessage';
import { EActionType, EAction, RegExPatterns } from '../../../data/Constants';
import { resetDialogMessage } from '../../../store/states/DialogSlice';
import { getMarkets,updateMarket,createMarket, getMarketDetails } from '../../../store/states/MarketSlice';
import { resetAlertMessage, setAlertMessage } from '../../../store/states/AlertMessageSlice';
import { resetError } from '../../../store/states/ErrorSlice';
import { IManipulationProps } from '../../../../types';
import { resetAllErrorInfo } from '../../../services/DataHelperFunctions';

//Call apis to create or update Markets
function callCreateOrUpdateApi( manipulationType: string, props: IManipulationProps, formData, t: ( message: string ) => string, dispatch: AppDispatch ) {
  delete formData['id'];
  if( manipulationType === EActionType.Edit ) {
    dispatch( updateMarket( { token: props.token, request: {...formData,name:formData.name.trimEnd(),description:formData.description.trim()} } ) ).then( res=>{
      handleApiResponse( res.payload,props,t( 'messages.fail.update' ),t( 'messages.success.update' ), dispatch );
    } )
  }else{
    dispatch( createMarket( { token: props.token, request: {...formData,name:formData.name.trimEnd(),description:formData.description.trim()} } ) ).then( res=>{
      handleApiResponse( res.payload,props,t( 'messages.fail.create' ),t( 'messages.success.create' ), dispatch );
    } )
  }
}

function handleApiResponse( res,props: IManipulationProps,failMessage:string,successMessage:string, dispatch: AppDispatch ) {
  const markets = AppStore.getState().dataMaintainance.markets;
  if( !res || res.error ) {
    const message = res?.error?.message ? res.error.message : failMessage
    dispatch( setAlertMessage( {show:true,message:message,type:'error'} ) )
  }else{
    dispatch( getMarkets( {token: props.token, page: markets.currentPage, limit: markets.recordsPerPage, searchCode: markets.searchKey} ) )
    dispatch( setAlertMessage( {show:true,message:successMessage,type:'success'} ) )
    dispatch( resetDialogMessage() );
  }
}

const loadFormData = async ( props: IManipulationProps,setInitialMarketData,setFormData, dispatch: AppDispatch ) => {
  let marketDetails = await dispatch( getMarketDetails( { token: props.token, request: [props.id] } ) ).unwrap()

  if( !marketDetails?.data ) {
    return;
  }
  marketDetails = marketDetails.data[0];

  setInitialMarketData( marketDetails );
  setFormData( marketDetails );
}

const setLocalAlertMessage = ( error, setDisplayAlertMessage ) => {
  if( error.code && ( error.action === EAction.Create || error.action === EAction.Update ) ) {
    setDisplayAlertMessage( true );
  }    
}

const formValidation = ( formValidationProps ) => {
  const { formData, initialMarketData, stringValidations, setDisplayNameErrorMessage, setDisplayCodeErrorMessage, setDisableButton } = formValidationProps;
  const name = formData.name.trimEnd();
  const code = formData.code.trimEnd();
  const validName = RegExPatterns.AlphaNumeric.test( name ) && name.length >= stringValidations.name.min;
  const validCode = RegExPatterns.MarketCode.test( code ) && code.length === stringValidations.code.length;
  const detailsUpdated = JSON.stringify( initialMarketData ) !== JSON.stringify( {...formData,name } );
  setDisplayNameErrorMessage( !validName );
  setDisplayCodeErrorMessage( !validCode );
  setDisableButton( !validName || !detailsUpdated || !validCode );
}

const handleChange = ( event,setFormData ) => {
  const name = event.target.name;    
  const value = name === 'isActive' ? event.target.checked : event.target.value;
  setFormData( values => ( {...values, [name]: value} ) )    
}

const ActionButton = ( handleSubmit, disableButton, manipulationType, t )=>{
  return <Button variant="contained" size="medium" onClick={ handleSubmit } className="text-capitalize" disabled={ disableButton }>
    { manipulationType === EActionType.Create ? t( 'button.create' ) : t( 'button.save' ) }
  </Button>
}

export const MarketManipulation = ( props: IManipulationProps ) => {
  const {t} = useTranslation();
  const manipulationType = props.type; //To get the type of manipulation being performed
  const [formData, setFormData] = useState( {'code':'','name':'','description':'','isActive':false} );
  const [disableButton, setDisableButton] = useState( true );
  const [initialMarketData, setInitialMarketData] = useState( {} );
  const [displayAlertMessage, setDisplayAlertMessage] = useState( false );
  const [displayNameErrorMessage, setDisplayNameErrorMessage] = useState( false );
  const [displayCodeErrorMessage, setDisplayCodeErrorMessage] = useState( false );
  const stringValidations = { 
    code: { length: 2 },
    name: { min: 3 }
  }

  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState )=> state.error )


  useEffect( ()=>{   
    if( manipulationType === EActionType.Edit ) {
      loadFormData( props, setInitialMarketData, setFormData, dispatch )
    }
  },[] )

  useEffect( ()=>{
    formValidation( { formData, initialMarketData, stringValidations, setDisplayNameErrorMessage, setDisplayCodeErrorMessage, setDisableButton } );
  } )

  useEffect( () => {     
    setLocalAlertMessage( error, setDisplayAlertMessage );
  }, [error] ) 

  const handleClose = ( event?: React.SyntheticEvent | Event, reason?: string ) => {    
    if ( reason === 'clickaway' ) {
      return;
    }
    dispatch( resetAlertMessage() );
    dispatch( resetError() );
    setDisplayAlertMessage( false );
  };
  
  const handleSubmit = ( event ) => {
    event.preventDefault();
    dispatch( resetError() ); 
    setDisplayAlertMessage( false );
    callCreateOrUpdateApi( manipulationType,props,formData,t, dispatch )
  }

  return <Box className="manipulationBox pt-1">     
    <Box className="manipulationForm">
      <Collapse in={ displayAlertMessage } className="show-alert">
        <Alert className="errorMessage" severity="error" onClose={ handleClose }>
          <AlertTitle><ErrorMessage error={ error } manipulationType={ manipulationType }/> </AlertTitle>
        </Alert>
      </Collapse>
      <TextField name="code" label={ t( 'labels.code' ) } required variant="outlined" size="small" fullWidth value={ formData.code || '' } onChange={ ( e )=>{
        handleChange( e, setFormData )
      } } disabled={ manipulationType === EActionType.Edit }
      /><br/>
      <Collapse in={ displayCodeErrorMessage && formData.code.length > 0 }>
        <Alert className="errorMessage" severity="error">
          <AlertTitle>{t( 'messages.codeErrorMessage' )} </AlertTitle>
        </Alert>
      </Collapse>
      <br/>
      <TextField name="name" label={ t( 'labels.name' ) } required variant="outlined" size="small" fullWidth value={ formData.name || '' } onChange={ ( e )=>{
        handleChange( e, setFormData )
      } } disabled = { manipulationType === EActionType.Edit && !formData.isActive }
      /><br/>
      <Collapse in={ displayNameErrorMessage && formData.name.length > 0 }>
        <Alert className="errorMessage" severity="error">
          <AlertTitle>{t( 'messages.nameErrorMessage' )} </AlertTitle>
        </Alert>
      </Collapse>
      <br/>
      <TextField name="description" label={ t( 'labels.description' ) } variant="outlined" size="small" fullWidth value={ formData.description || '' } onChange={ ( e )=>{
        handleChange( e, setFormData )
      } } disabled = { manipulationType === EActionType.Edit && !formData.isActive }
      /><br/>
      <br/>
      {!initialMarketData.isActive && manipulationType === EActionType.Edit ?
        <> <FormControlLabel
          value={ formData.isActive }
          checked={ !!formData.isActive }
          control={ <Checkbox /> }
          label={ t( 'labels.isActive' ) }
          labelPlacement="end"
          onChange={ ( e )=>{
            handleChange( e, setFormData )
          } }
          name="isActive"
        />
        <br/></> : null
      }
      <Box className="formActionButtons">
        {ActionButton( handleSubmit, disableButton, manipulationType, t )}
        <Button size="medium" onClick={ ()=> {
          resetAllErrorInfo() 
          setDisplayAlertMessage( false ); 
        } } className="text-capitalize"
        >{ t( 'button.cancel' ) } </Button>
      </Box>
    </Box>
  </Box>
}