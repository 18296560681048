import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppStore, AppDispatch, AppState } from '../../../store';
import { IManagementProps } from '../../../../types';
import { ErrorMessage } from '../../ErrorMessage';
import { MaintenanceTable } from '../../Layouts/DataTable';
import { EDataTableType,EModuleType,EApiType } from '../../../data/Constants';
import { joinSelectedKeys } from '../../../services/DataHelperFunctions';
import { getApplications, deleteApplications } from '../../../store/states/ApplicationSlice';
import { setAlertMessage } from '../../../store/states/AlertMessageSlice';
import { getEnumListOfValues } from '../../../store/states/PropertySlice';


export const ApplicationManagement = ( props:IManagementProps ) => {
  
  const {t} = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const error = useSelector( ( state:AppState )=> state.error )


  //Call get Enum LOV list API 
  useEffect( ()=>{
    dispatch( getEnumListOfValues( ) );
  },[] )
  
  const deleteItems = ( req )=>{
    const applications = AppStore.getState().administration.applications;
    dispatch( deleteApplications( {token: props.token, request: req } ) ).then( res=>{
      const delKeys = joinSelectedKeys( req );
      if( res.payload?.data ) {
        const message = t( 'messages.success.delete.applications', { length:req.length, list:delKeys } )
        dispatch( setAlertMessage( {show:true, message: message, type:'success'} ) )
        dispatch( getApplications( { token: props.token, page: applications.currentPage, limit: applications.recordsPerPage, searchCode: applications.searchKey} ) );
      }else{
        const message = t( 'messages.fail.delete.applications', { length:req.length, list: delKeys } );
        dispatch( setAlertMessage( { show:true, message:message, type:'error' } ) )
      }
    } )
  }
  
  if( error.code && error.type === EApiType.ApplicationKey ) {
    return <h4>
      <ErrorMessage error={ error } />
    </h4>
  }

  return <>
    <h3 className="mainHeader"> {t( 'labels.applicationManagement' )}</h3>
    
    <MaintenanceTable
      token={ props.token }
      type={ EDataTableType.Applications }
      getDataList = { getApplications }
      moduleType = { EModuleType.Administration }
      deleteItems={ deleteItems }
    />
  </>
}